.coverTheEachHere{
    display: flex;
    /* border: 1px solid rgb(255, 141, 0); */
    width: max-content;
    max-width: 100%;
    margin: .4em;
    flex-direction: column !important;
}

.coverTheEachHere label{
    text-align: left;
}
.absoluteDelete{
    position: absolute;top: 20px;
    right: 20px;
}
.coverTheMap{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 90%;
    margin: auto;
    margin-top: 40px;
}
/* .coverTheEachHere textarea{
    width: 400px !important  ;
} */