* {
  box-sizing: border-box;
}

/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.hlp.badge {
  color: #fff;
  font-size: 100%;
}

table.list {
  width: 100%;
}

table.list .row1 {
  background: #fce7ca;
}

table.list td {
  width: auto;
  padding: 3px;
}

table.list th {
  width: auto;
  padding: 3px;
  text-align: center;
  background: #ffc982;
  color: #493011;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
  width: 90%;
  margin: auto;
  border-radius: 50px;
  overflow: hidden;
  padding: 2em;
}
.AheadAheadTop {
  margin-top: -60px;
}

.App-link {
  color: #61dafb;
}

.sbmt, form button, form input[type=button], form input[type=submit] {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.25rem 0.5rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  border: none;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  margin: 0.25em;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

table.form th {
  width: 30%;
  padding: 3px;
  padding-right: 5px;
  text-align: right;
  background: #ffc982;
  color: #493011;
}

th {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 11px;
}

table.form {
  width: 100%;
}

table.form td {
  width: 70%;
  padding: 3px;
  text-align: left;
  background: #fef5ea;
}

.sbmt.btn-sm {
  font-size: 0.525rem;
  padding: 0.25rem 0.35rem;
  text-transform: uppercase;
  cursor: pointer;
  margin: 1px;
}

.sbmt {
  background-color: #ffecb0;
  border: 1px outset #ffffe1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
