.mainCover{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.label{
    margin-bottom: 0.rem;
    color: #6c757d;
    font-weight: 600;
    font-size: 13px;
}

.inputCover{
    width: 100%;
    height: calc(1.5em + 0.9rem + 2px);
    padding: 0.45rem 0.9rem;
    font-size: .875rem;
    outline: none;
    font-weight: 400;
    box-sizing: border-box;
    line-height: 1.5;
    display: block;
    color: #6c757d;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    margin-top: 8px;
    border-radius: 0.2rem;
}