.loginCover {
  background-image: url('../../assets/bg-pattern.png');
  background-color: #000000;
  background-size: cover;
  background-position: center;
  padding-bottom: 60px;
  overflow-x: hidden;
}

.cardBody {
  padding: 2.25rem !important;
  background-image: url(../../assets/bg-pattern-2.png);
  width: 450px;
  background-size: cover;
  background-color: #fff;
  box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);
  background-clip: border-box;
  margin: 4.5rem auto 1rem auto;
  border: 0 solid #f7f7f7;
  box-sizing: border-box;
  border-radius: 0.25rem;
  height: 527px;
  max-width: 97%;
  font-size: 0.875rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  color: #6c757d;
}

.logoSvg {
  width: 256px;
  vertical-align: middle;
  margin: 0px auto 0px auto;
}

.header {
  margin-top: 1.5rem !important;
  /* margin: 1px 0; */
  font-size: 1.5rem;
  text-align: center;
  color: #343a40;
}

.small {
  color: #98a6ad;
  margin: 0px auto;
  text-align: center;
  margin-bottom: 1rem;
  width: 288px;
  font-size: 14px;
}

.checkbox {
  -webkit-appearance: none;
  width: 1rem;
  height: 1rem;
  background: no-repeat 50%/50% 50%;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  pointer-events: none;
  content: '';
  background-color: #fff;
  z-index: 100px;
  margin-right: 8px;
  border: #adb5bd solid 1px;
}

.checkbox:checked {
  background-color: rgb(255, 141, 0) !important;
  border: rgb(255, 141, 0) solid 1px;
  background-image: url(../../assets/checked.svg);
  padding-top: 4px;
}

.button {
  border: 1px solid rgb(255, 141, 0);
  display: flex;
  justify-content: center;
  color: #fff;
  width: 100%;
  cursor: pointer;
  padding: 0.45rem 0.9rem;
  font-size: 0.875rem;
  height: 45px;
  align-items: center;
  line-height: 1.5;
  border-radius: 0.15rem;
  background-color: rgb(255, 141, 0);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.button:disabled {
  background-color: #ff016699;
  border: 1px solid #ff016698;
}

.button:hover {
  background-color: #020032;
  border-color: #020032;
}

.button:disabled:hover {
  border: 1px solid #02003299;
  background-color: #02003299;
}

.footer {
  display: flex;
  width: max-content;
  font-size: 13px;
  color: #fff;
  margin-top: 110px;
}

.msgAlert {
  color: #806027;
  background-color: #fdf1db;
  position: relative;
  box-sizing: border-box;
  padding: 0.75rem 1.25rem;
  border: 1px solid #fdebcd;
  border-radius: 0.25rem;
}

.msgItself {
  color: #323a46 !important;
}
